import React from "react";
import { Modal, Carousel, Button, Icon } from "antd";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Gallery = props => {
  return (
    <Modal
      style={{ top: 10 }}
      bodyStyle={{ background: "black" }}
      title={props.productData.productnm}
      visible={props.showGallery}
      onCancel={() => props.setShowGallery(false)}
      footer={[
        <Button key="back" onClick={() => props.setShowGallery(false)}>
          Cancel
        </Button>
      ]}
    >
      <Carousel autoplay adaptiveHeight arrows={true} accessibility={true}>
        {props.productData.gallery.map((imgURL, idx) => (
          <img
            key={idx}
            src={imgURL}
            alt="No image available"
          />
        ))}
      </Carousel>
    </Modal>
  );
};
export default Gallery;

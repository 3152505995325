import * as types from "./actionTypes";

const initialState = {
  authed: null,
  user: null,
  error: null,
  loading: false,
  categories: [],
  products: []
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case types.RESTORE_SESSION_REQUEST:
      return { ...state, loading: true, error: null, authed: null };
    case types.RESTORE_SESSION_SUCCESS:
      return { ...state, loading: false, user: action.user, authed: true };
    case types.RESTORE_SESSION_FAILURE:
      return { ...state, loading: false, authed: false };
    case types.LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case types.LOGIN_SUCCESS:
      return { ...state, loading: false, user: action.user, authed: true };
    case types.LOGIN_FAILURE:
      return { ...state, loading: false, error: action.error, authed: false };
    case types.LOGOUT_REQUEST:
      return { ...state, loading: true, error: null, authed: null };
    case types.LOGOUT_SUCCESS:
      return { ...state, loading: false, user: null, authed: false };
    case types.LOGOUT_FAILURE:
      return { ...state, loading: false, error: action.error, authed: true };
    case types.GET_PRODUCTS_REQUEST:
      return { ...state, loading: true, error: null, products: [] };
    case types.GET_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.products };
    case types.GET_PRODUCTS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case types.GET_CATEGORIES_REQUEST:
      return { ...state, loading: true, error: null, categories: [] };
    case types.GET_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: action.categories };
    case types.GET_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};

export default session;

import React, { useEffect, useState } from "react";
import variables from "../../variables.scss";
import { List, Card, Spin, Menu, Affix } from "antd";
import ScrollToTop from "../ScrollToTop";
import { useSelector, useDispatch } from "react-redux";
import { getCategoriesData, getProductsData } from "../../store/session";
import Gallery from "../Gallery";

const { Meta } = Card;

const Products = props => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.session.products);
  const categories = useSelector(state => state.session.categories);
  const loading = useSelector(state => state.session.loading);
  const [productData, setProductData] = useState();
  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    dispatch(getCategoriesData());
  }, [dispatch]);

  useEffect(() => {
    if (categories.length > 0) {
      dispatch(
        getProductsData(props.location.pathname, categories[0].categoryid)
      );
    }
  }, [categories]);

  return (
    <div className="App-layout" style={{ display: "block" }}>
      <ScrollToTop />
      <Spin spinning={loading} size="large">
        <Affix offsetTop={50}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            style={{ marginBottom: 20 }}
          >
            {categories &&
              categories.map((item, key) => (
                <Menu.Item
                  key={item.categoryid}
                  onClick={() => {
                    dispatch(
                      getProductsData(props.location.pathname, item.categoryid)
                    );
                  }}
                >
                  {item.categorynm}
                </Menu.Item>
              ))}
          </Menu>
        </Affix>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 3
          }}
          dataSource={products}
          renderItem={item => (
            <List.Item>
              <Card
                onClick={() => {
                  setProductData(item);
                  setShowGallery(true);
                }}
                hoverable
                cover={<img alt="product" src={item.image} />}
              >
                <Meta
                  title={item.productnm}
                  description={item.price + " บาท"}
                />
              </Card>
            </List.Item>
          )}
        />

        {showGallery && productData && (
          <Gallery
            showGallery={showGallery}
            setShowGallery={setShowGallery}
            productData={productData}
          />
        )}
      </Spin>
    </div>
  );
};

export default Products;

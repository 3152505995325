import * as types from "./actionTypes";
import firebase from "../../configs/firebase";
import * as constants from "../../configs/constants";
import * as routes from "../../configs/routes";

export const restoreSession = () => {
  return dispatch => {
    console.log("restoring session...");
    dispatch({ type: types.RESTORE_SESSION_REQUEST });
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch({ type: types.RESTORE_SESSION_SUCCESS, user: user });
      } else {
        dispatch({ type: types.RESTORE_SESSION_FAILURE });
      }
    });
  };
};

export const loginUser = (email, password) => dispatch => {
  dispatch({ type: types.LOGIN_REQUEST });
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(user => {
      dispatch({ type: types.LOGIN_SUCCESS, user: user });
    })
    .catch(error => {
      dispatch({ type: types.LOGIN_FAILURE, error: error.message });
    });
};

export const logoutUser = () => {
  return dispatch => {
    dispatch({ type: types.LOGOUT_REQUEST });
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: types.LOGOUT_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: types.LOGOUT_FAILURE, error: error.message });
      });
  };
};

export const getCategoriesData = () => {
  return dispatch => {
    dispatch({ type: types.GET_CATEGORIES_REQUEST });
    firebase
      .firestore()
      .collection(constants.COLLECTION_CATEGORIES)
      .where(constants.COLUMN_ACTIVE, "==", true)
      .orderBy(constants.COLUMN_ORDER)
      .get()
      .then(function(querySnapshot) {
        let categories = [];
        querySnapshot.forEach(function(doc) {
          let category = { ...doc.data(), key: doc.id };
          categories.push(category);
        });
        dispatch({ type: types.GET_CATEGORIES_SUCCESS, categories });
      });
  };
};

export const getProductsData = (route, categoryid) => {
  return dispatch => {
    
    dispatch({ type: types.GET_PRODUCTS_REQUEST });
    if (route === routes.HOME) {
      firebase
        .firestore()
        .collection(constants.COLLECTION_PRODUCTS)
        .where(constants.COLUMN_ACTIVE, "==", true)
        .where(constants.COLUMN_SHOWATHOME, "==", true)
        .get()
        .then(function(querySnapshot) {
          let products = [];
          querySnapshot.forEach(function(doc) {
            let product = { ...doc.data(), key: doc.id };
            products.push(product);
          });
          dispatch({ type: types.GET_PRODUCTS_SUCCESS, products });
        });
    } else if (route === routes.PRODUCTS) {
      firebase
        .firestore()
        .collection(constants.COLLECTION_PRODUCTS)
        .where(constants.COLUMN_CATEGORYID, "==", categoryid)
        .where(constants.COLUMN_ACTIVE, "==", true)
        // .where(constants.COLUMN_SHOWATPRODUCT, "==", true)        
        .get()
        .then(function(querySnapshot) {
          let products = [];
          querySnapshot.forEach(function(doc) {
            let product = { ...doc.data(), key: doc.id };
            products.push(product);
          });
          dispatch({ type: types.GET_PRODUCTS_SUCCESS, products });
        });
    }
  };
};

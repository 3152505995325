import React from "react";
import { Route } from "react-router-dom";
import * as routes from "../../configs/routes";
import { Row, Col } from "antd";
import AppBar from "../AppBar";
import Home from "../Home";
import Products from "../Products";

const Dashboard = props => {
  return (
    <React.Fragment>
      <AppBar />
      <Route path={routes.HOME} exact component={Home} />
      <Route path={routes.PRODUCTS} exact component={Products} />

      <Row style={{width: '100%', backgroundColor:'#F9E79F', fontSize: 14, textAlign: 'center', padding: 5, position: 'fixed', bottom: 0}}>
        <Col xs={12} md={6}>
          <b>ประสานจันทบูร</b>
        </Col>
        <Col xs={12} md={6}>
          <b>Line ID : 085-2309696</b>
        </Col>
        <Col xs={12} md={6}>
          <b>เบอร์โทรศัพท์: 085-2309696</b>
        </Col>
        <Col xs={12} md={6}>
          <b>แฟกซ์: 039-491699</b>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;

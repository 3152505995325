import React from "react";
import { ReactComponent as AppLogo } from "../../assets/images/logo.svg";
import { Spin } from "antd";
import ScrollToTop from "../ScrollToTop";

const SplashScreen = props => {
  return (
    <div className="App-layout">
      <ScrollToTop />
      <AppLogo
        className="App-logo"
        style={{ marginBottom: 40, marginTop: "5em" }}
      />
      <Spin size="large" />
    </div>
  );
};

export default SplashScreen;

import React, { useEffect, useState } from "react";
import variables from "../../variables.scss";
import { List, Card, Spin } from "antd";
import ScrollToTop from "../ScrollToTop";
import { useSelector, useDispatch } from "react-redux";
import { getProductsData } from "../../store/session";
import Gallery from "../Gallery";

const { Meta } = Card;

const Home = props => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.session.products);
  const loading = useSelector(state => state.session.loading);
  const [productData, setProductData] = useState();
  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    dispatch(getProductsData(props.location.pathname, 0));
  }, [dispatch]);

  return (
    <div className="App-layout" style={{ display: "block" }}>
      <ScrollToTop />
      <Spin spinning={loading} size="large">
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 3
          }}
          dataSource={products}
          renderItem={item => (
            <List.Item>
              <Card
                onClick={() => {
                  setProductData(item);
                  setShowGallery(true);
                }}
                hoverable
                cover={<img alt="product" src={item.image} />}
              >
                <Meta
                  title={item.productnm}
                  description={item.price + " บาท"}
                />
              </Card>
            </List.Item>
          )}
        />

        {showGallery && productData && (
          <Gallery
            showGallery={showGallery}
            setShowGallery={setShowGallery}
            productData={productData}
          />
        )}
      </Spin>
    </div>
  );
};

export default Home;

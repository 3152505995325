import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { restoreSession } from "../../store/session";
import SplashScreen from '../SplashScreen';
import Dashboard from '../Dashboard';

const App = props => {
  const authed = useSelector(state => state.session.authed);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restoreSession());
  }, [dispatch]);

  if (authed == null) {
    return <SplashScreen />;
  }
  return <Dashboard />;
};

export default App;

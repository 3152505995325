import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Icon, Button, Menu, Drawer } from "antd";
import { ReactComponent as AppLogo } from "../../assets/images/logo.svg";
import variables from "../../variables.scss";

const AppBar = props => {
  const [drawerState, setDrawerState] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");

  useEffect(() => {
    switch (props.location.pathname) {
      case "/":
        setSelectedKey("home");
        break;
      case "/login":
        setSelectedKey("login");
        break;
      case "/products":
        setSelectedKey("products");
        break;

      default:
        break;
    }
  }, [props.location.pathname]);

  return (
    <React.Fragment>
      <CustomFooter setDrawerState={setDrawerState} history={props.history} selectedKey={selectedKey} />
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setDrawerState(false)}
        visible={drawerState}
      >
        <Menu
          mode="inline"
          selectedKeys={selectedKey}
        >
          <Menu.Item
            key="home"
            onClick={() => {
              setDrawerState(false);
              props.history.push("/");
            }}
          >
            <Icon type="home" />
            <span>หน้าหลัก</span>
          </Menu.Item>
          <Menu.Item
            key="products"
            onClick={() => {
              setDrawerState(false);
              props.history.push("/products");
            }}
          >
            <Icon type="build" />
            <span>สินค้าทั้งหมด</span>
          </Menu.Item>
        </Menu>
      </Drawer>
    </React.Fragment>
  );
};

const CustomFooter = props => (
  <Row type="flex" className="Appbar">
    <Col xs={18} md={12} lg={16}>
      <Button
        type="link"
        onClick={() => props.history.push("/")}
        style={{ fontSize: 26, color: `${variables.appColor}` }}
      >
        <Icon component={AppLogo} style={{ color: `${variables.iconColor}` }} />
        ThaiGenerators
      </Button>
    </Col>
    <Col xs={0} md={12} lg={8} style={{ textAlign: "right" }}>
      {/* <Button
        type="link"
        onClick={() => props.history.push("/")}
        style={{ fontSize: 16, color: `${variables.appColor}` }}
      >
        <Icon type="home" />
        หน้าหลัก
      </Button>
      <Button
        type="link"
        onClick={() => props.history.push("/products")}
        style={{ fontSize: 16, color: `${variables.appColor}` }}
      >
        <Icon type="build" />
        สินค้าทั้งหมด
      </Button> */}
      <Menu
        mode="horizontal"
        style={{ background: "none", color: "white" }}
        selectedKeys={props.selectedKey}
      >
        <Menu.Item key="home" onClick={() => props.history.push("/")}>
          <Icon type="home" />
          หน้าหลัก
        </Menu.Item>
        <Menu.Item
          key="products"
          onClick={() => props.history.push("/products")}
        >
          <Icon type="build" />
          สินค้าทั้งหมด
        </Menu.Item>
      </Menu>
    </Col>
    <Col xs={6} md={0} style={{ textAlign: "right", paddingRight: 10 }}>
      <Button
        type="link"
        onClick={() => props.setDrawerState(true)}
        icon="menu"
        style={{ fontSize: 26, color: "white" }}
      />
    </Col>
  </Row>
);

export default withRouter(AppBar);
